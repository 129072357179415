.map {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
}
.leaflet-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Search {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  z-index: 99999;
  width: 80%;
  margin: 2rem 0;
  input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    /*  */

    color: white;
    &.mode-suggestion {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &:focus {
      box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.2);
    }
  }
}

.search-container {
  height: 2.5rem;
  width: 80%;
  position: relative;
}

input ::placeholder {
  color: white;
  opacity: 0.3;
}
.search-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon {
  height: 1.5rem;
  width: 1.5rem;
  color: rgba(255, 255, 255, 0.75);
}

.suggestions {
  width: 80%;
  position: relative;
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 0.2rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.content {
  width: 100%;
  height: 100%;
  color: white;
  cursor: pointer;
  padding: 1px;
}

.content div {
  padding: 0.001rem 2rem;
  margin: 0.2rem;
  border-radius: 10px;
}

.content div:hover {
  box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.2);
}
