.kio-loader {
  animation: 1s ease-in-out infinite spiner;
  @keyframes spiner {
    0% {
      opacity: 0.75;
      transform: rotate(-360deg) scale(0.5);
    }
    50% {
      transform: rotate(0deg) scale(1.25);
      opacity: 1;
    }
    100% {
      transform: rotate(0deg) scale(1);
      opacity: 0.75;
    }
  }
  &.exit {
    animation: 1s ease-in-out exit;
    @keyframes exit {
      0% {
        transform: rotate(0deg) scale(1);
        opacity: 0.75;
      }
      100% {
        transform: scale(0);
        opacity: 0;
      }
    }
  }
}
