/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .truncate-3 {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }


  @variants direction, responsive{
    
.rtl-text{
  direction : rtl;
}
.ltr-text{
  direction : ltr;
}
  }



  /* width */
  .custom-scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  .custom-scroll::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #edf2f7;
  }

  /* Handle */
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 100vh;
    border: 3px solid #edf2f7;
  }

  /* Handle on hover */
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
  }
}

@font-face {
  font-family: "Gibson Regular";
  font-style: normal;
  font-weight: 500;
  src: local("Gibson Regular"),
    url("./assets/fonts/GibsonRegular.woff") format("woff");
}

@font-face {
  font-family: "Gibson Light";
  font-style: normal;
  font-weight: 200;
  src: local("Gibson Light"),
    url("./assets/fonts/GibsonLight.woff") format("woff");
}

@font-face {
  font-family: "Gibson SemiBold";
  font-style: normal;
  font-weight: 700;
  src: local("Gibson SemiBold"),
    url("./assets/fonts/GibsonSemiBd.woff") format("woff");
}

@font-face {
  font-family: "Gibson Bold";
  font-style: normal;
  font-weight: 800;
  src: local("Gibson Bold"),
    url("./assets/fonts/GibsonBold.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Gibson Regular", sans-serif;
  max-width: 100vw;
}
* {
  box-sizing: border-box !important;
}

/* react-input-phone border style  */
.border-focus {
  --tw-border-opacity: 1;
  border: 1px solid rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}
.border-focus:focus {
  /* react-input-phone style of focus */
  --tw-border-opacity: 1;
  border: 1px solid rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}
/* react-input-phone border style  */
.border-phone {
  --tw-border-opacity: 1;
  border: 1px solid rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}
.border-phone:focus {
  /* react-input-phone style of focus */
  --tw-border-opacity: 1;
  border: 1px solid rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}
/* error border of react-phone */
.border-phone-error {
  --tw-border-opacity: 1;
  border: 1px solid rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}
/* error border focus of react-phone */
.border-phone-error:focus {
  --tw-border-opacity: 1;
  border: 1px solid rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

/* Chrome, Safari, Edge, Opera */
.hide-input-arrow::-webkit-outer-spin-button,
.hide-input-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.hide-input-arrow[type="number"] {
  -moz-appearance: textfield;
}

body,
html,
#root {
  width: 100%;
  height: 100%;
}
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

